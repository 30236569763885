import { useState, useEffect, useRef } from 'react'
import Image from 'next/future/image'
import { AnimatePresence, motion, useScroll } from 'framer-motion'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import { Link, FacebookIcon, InstagramIcon } from '~/components'
import { Header } from './Header'
import { Footer } from './Footer'

import * as styles from './Layout.css'

import logo from './assets/genussfestival-logo.svg'
import logoSmall from './assets/genussfestival-logo-small.svg'
import { useClickOutside } from './useClickOutside'

type Props = React.ComponentPropsWithoutRef<'div'>

const smallLogoVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 600, damping: 50 } },
}

const overlayVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

const menuItems = [
  {
    href: '/',
    text: 'Home',
  },
  {
    href: '/rathausplatz-events',
    text: 'Rathausplatz-Events',
  },
  {
    href: '/eventkalender',
    text: 'Eventkalender',
  },
  {
    href: '/koeche',
    text: 'Köche',
  },
  {
    href: '/restaurants-geschaefte',
    text: 'Restaurants & Geschäfte',
  },
  {
    href: '/wettbewerb',
    text: 'Gastro-Wettbewerb',
  },
  {
    href: '/partner',
    text: 'Partner',
  },
  {
    href: '/info',
    text: 'Info',
  },
]

export function Layout({ children }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [showSmallLogo, setShowSmallLogo] = useState(false)
  // const { ref, inView, entry } = useInView();
  const { scrollY } = useScroll()
  const [menuOpen, setMenuOpen] = useState(false)
  const router = useRouter()
  useClickOutside(ref, (e: any) => {
    e.stopPropagation()
    if (menuOpen) {
      setMenuOpen(false)
      return
    }
  })

  useEffect(() => {
    function handleRouteChange(url: string) {
      setMenuOpen(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  function onClick(e: any) {
    e.stopPropagation()
    setMenuOpen((state) => !state)
  }

  scrollY.onChange((y) => {
    if (y > 300) {
      setShowSmallLogo(true)
    } else {
      setShowSmallLogo(false)
    }
  })

  return (
    <div className={styles.layout}>
      <Header />
      <button
        className={classNames([styles.button, menuOpen && styles.buttonActive])}
        onClick={onClick}
        aria-label="Menü öffnen"
        id="menu-button"
      >
        <span className={styles.line}></span>
        <span className={styles.line}></span>
        <span className={styles.line}></span>
      </button>

      <AnimatePresence>
        {menuOpen && (
          <motion.div
            className={styles.overlay}
            initial="hidden"
            animate={menuOpen ? 'visible' : 'hidden'}
            variants={overlayVariants}
            ref={ref}
          >
            <nav className={styles.menu}>
              <ul className={styles.menuList}>
                {menuItems.map(({ href, text }) => (
                  <li key={href}>
                    <Link
                      href={href}
                      className={styles.menuLink}
                      data-active={href === '/' ? router.asPath === '/' : router.asPath.includes(href)}
                    >
                      <span className={styles.menuLinkSpan}>{text}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            <div className={styles.meta}>
              <div className={styles.metaContent}>
                <div className={styles.metaShare}>
                  Bleib informiert:
                  <ul className={styles.metaShareList}>
                    <li>
                      <a
                        href="https://www.instagram.com/genussfestival_vaduz/"
                        className={styles.metaShareLink}
                        aria-label="Instagram"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon className={styles.metaShareIcon} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/Genussfestival-Vaduz-105210368954470"
                        className={styles.metaShareLink}
                        aria-label="Facebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FacebookIcon className={styles.metaShareIcon} />
                      </a>
                    </li>
                  </ul>
                </div>
                <ul className={styles.metaList}>
                  <li>
                    <Link
                      href="/impressum"
                      className={classNames([styles.metaLink, router.asPath === '/impressum' && styles.metaLinkActive])}
                    >
                      Impressum
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/disclaimer"
                      className={classNames([
                        styles.metaLink,
                        router.asPath === '/disclaimer' && styles.metaLinkActive,
                      ])}
                    >
                      Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/datenschutz"
                      className={classNames([
                        styles.metaLink,
                        router.asPath === '/datenschutz' && styles.metaLinkActive,
                      ])}
                    >
                      Datenschutz
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <Link href="/">
        <Image
          src={logo}
          alt="Genussfestival Vaduz Logo"
          width={288}
          height={288}
          className={styles.logo}
          loading="eager"
        />
        <motion.div
          animate={showSmallLogo ? 'visible' : 'hidden'}
          variants={smallLogoVariants}
          className={styles.logoSmall}
        >
          <Image
            src={logoSmall}
            alt="Genussfestival Vaduz Logo"
            width={70}
            height={70}
            className={styles.logoSmallImage}
          />
        </motion.div>
      </Link>
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}
