import Link from 'next/link'
import classNames from 'classnames'

import { button, ButtonVariants } from './Button.css'

type NativeComponent = React.ComponentPropsWithoutRef<'button'> | React.ComponentPropsWithoutRef<'a'>

type ButtonProps = NativeComponent &
  ButtonVariants & {
    href?: string
    target?: any
    active?: boolean
    external?: boolean
  }

export function Button({ href, children, color, active, variant, external, target, className, ...props }: ButtonProps) {
  const classes = classNames([button({ variant, color }), className])

  if (href) {
    if (external) {
      return (
        <>
          {/* @ts-ignore:next-line */}
          <a href={href} className={classes} target={target} {...props} data-active={active}>
            {children}
          </a>
        </>
      )
    }

    return (
      <Link href={href}>
        {/* @ts-ignore:next-line */}
        <a className={classes} target={target} {...props} data-active={active}>
          {children}
        </a>
      </Link>
    )
  }

  return (
    // @ts-ignore
    <button className={classes} {...props} data-active={active}>
      {children}
    </button>
  )
}
